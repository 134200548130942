import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  //{
  //  path: 'home',
  //  loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  //},
  {
    path: '',
    redirectTo: 'produkcategory',
    pathMatch: 'full'
  },
  {
    path: 'produkcategory',
    loadChildren: () => import('./pages/produkcategory/produkcategory.module').then( m => m.ProdukcategoryPageModule)
  },
  {
    path: 'produk',
    loadChildren: () => import('./pages/produk/produk.module').then( m => m.ProdukPageModule)
  },
  {
    path: 'partner',
    loadChildren: () => import('./pages/partner/partner.module').then( m => m.PartnerPageModule)
  },
  {
    path: 'company',
    loadChildren: () => import('./pages/company/company.module').then( m => m.CompanyPageModule)
  },
  {
    path: 'datapasien',
    loadChildren: () => import('./pages/datapasien/datapasien.module').then( m => m.DatapasienPageModule)
  },
  {
    path: 'detail',
    loadChildren: () => import('./pages/detail/detail.module').then( m => m.DetailPageModule)
  },
  {
    path: 'jumlahpaket',
    loadChildren: () => import('./pages/jumlahpaket/jumlahpaket.module').then( m => m.JumlahpaketPageModule)
  },
  {
    path: 'konfirmasipembayaran',
    loadChildren: () => import('./pages/konfirmasipembayaran/konfirmasipembayaran.module').then( m => m.KonfirmasipembayaranPageModule)
  },
  {
    path: 'konfirmasitransaksi',
    loadChildren: () => import('./pages/konfirmasitransaksi/konfirmasitransaksi.module').then( m => m.KonfirmasitransaksiPageModule)
  },
  {
    path: 'notifikasi',
    loadChildren: () => import('./pages/notifikasi/notifikasi.module').then( m => m.NotifikasiPageModule)
  },
  {
    path: 'operasional',
    loadChildren: () => import('./pages/modal/operasional/operasional.module').then( m => m.OperasionalPageModule)
  },
  {
    path: 'calendar',
    loadChildren: () => import('./pages/modal/calendar/calendar.module').then( m => m.CalendarPageModule)
  },
  {
    path: 'lokasi',
    loadChildren: () => import('./pages/modal/lokasi/lokasi.module').then( m => m.LokasiPageModule)
  },
  {
    path: 'lokasimitra',
    loadChildren: () => import('./pages/lokasi/lokasi.module').then( m => m.LokasiPageModule)
  },
  {
    path: 'detailbanner',
    loadChildren: () => import('./pages/detailbanner/detailbanner.module').then( m => m.DetailbannerPageModule)
  },
  {
    path: 'consent',
    loadChildren: () => import('./pages/modal/consent/consent.module').then( m => m.ConsentPageModule)
  },
  {
    path: 'konfirmasi',
    loadChildren: () => import('./pages/modal/konfirmasi/konfirmasi.module').then( m => m.KonfirmasiPageModule)
  },
  {
    path: 'error',
    loadChildren: () => import('./pages/modal/error/error.module').then( m => m.ErrorPageModule)
  },
  {
    path: 'detailpaket',
    loadChildren: () => import('./pages/detailpaket/detailpaket.module').then( m => m.DetailpaketPageModule)
  },
  
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
