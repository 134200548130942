import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { HealthcareService } from "./service/healthcare.service";
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { Device } from '@ionic-native/device/ngx';
//import { MaterialModule, MatDatepickerModule, MatNativeDateModule } from '@angular/material';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import {enterAnimation} from './nav/navi-test';

@NgModule({
    declarations: [AppComponent],
    entryComponents: [],
    imports: [
        BrowserModule, 
        IonicModule.forRoot({animated: true, navAnimation: enterAnimation}), 
        AppRoutingModule, 
        BrowserAnimationsModule,
        FormsModule, 
        HttpClientModule,
        MatDatepickerModule,
        MatNativeDateModule 
    ],
    exports: [
        MatDatepickerModule, 
        MatNativeDateModule 
    ],
    providers: [
        Device,
        HealthcareService,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
