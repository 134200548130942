import { Component,ViewChildren, QueryList } from '@angular/core';
import { NavController, NavParams } from '@ionic/angular';
import { MatExpansionModule } from '@angular/material/expansion';
import { Platform } from '@ionic/angular';
import { enableProdMode } from '@angular/core';

enableProdMode();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  lastTimeBackPress = new Date().getTime();
  timePeriodToExit = 1000;

  constructor(
  	private platform: Platform,
    
  ) {
  	
    this.backButtonEvent();
  }


  backButtonEvent() {
	    this.platform.backButton.subscribe(async () => {
	        if (new Date().getTime() - this.lastTimeBackPress < this.timePeriodToExit) {
	            navigator['app'].exitApp(); 
	        }
	        this.lastTimeBackPress = new Date().getTime();
	    });
  }
}
