import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AlertController } from '@ionic/angular';
import { LoadingController } from '@ionic/angular';
import { RouterModule, Router, NavigationExtras } from '@angular/router';
//import { Router } from '@angular/router';
import { ToastController } from "@ionic/angular";
import * as CryptoJS from "crypto-js";
@Injectable({
    providedIn: 'root'
})

export class HealthcareService {
    //baseUrl: string = "https://api.hc.agratek.id";
    // baseUrl: string = "https://dashboard.hc.agratek.id/healthcare/api";
    baseUrl: string = "https://dashboard.devhc.agratek.id/healthcare/api";

    AlertController: any;

    produkid: string = localStorage.getItem('produk_id');
    
    api_key: string = "toh2UjnRQzYIWmheJaasE1gJMjDZsdyNlpSvTS6M3DrLRruvpOHzcABycEPwCYVx";
    username: string = "healthcare";
    timestamp = "";
    signature = "";
    istoast = false;
    isLoading = false;

    dictRilliv ={
        //DEVELOPMENT
        "company_id": 72,

        //PRODUCTION
        // "company_id": 120,
        "company_nm": "Riliv Psikologi Indonesia",
        "kecamatan": "Pondok Jati",
        "kota": "Sidoarjo",
        "propinsi": "Jawa Timur",
        "produk_id_rilliv":[19,20,21,22,23,24]
    }
    
    constructor(
        private httpClient: HttpClient,
        private toas: ToastController,
        public LoadingController: LoadingController,
    ) {}

    public getstringDateNow() {
        let d = new Date();
        let year = d.getFullYear();
        let month = d.getMonth()+1 < 10 ? "0"+(d.getMonth()+1) :d.getMonth()+1;
        let date = d.getDate() < 10 ? "0" +d.getDate() :d.getDate();
        return date.toString() +"-" + month.toString()+"-" + year.toString()
    }

    public getProdukCategory() {
            let headers = this.generateHeader();
            let par = {
                "jsonrpc": "2.0",
                "method": "getProdukCategory",
                "params": {
                    "data": []
                },
                "id": "1"
            }
            return this.httpClient.post(this.baseUrl, JSON.stringify(par), { headers });
    }
    public getProduk(category_id) {
            let headers = this.generateHeader();
            let par = {
            "jsonrpc": "2.0",
            "method": "getProduk",
            "params": {
                "data": [{
                    "category_id": category_id
                }]
            },
            "id": "1"
            }
            return this.httpClient.post(this.baseUrl, JSON.stringify(par), { headers });
    }
    public getCompanyParent(produk_id) {
        let headers = this.generateHeader();
        let par = {
            "jsonrpc": "2.0",
            "method": "getCompanyParent",
            "params": {
                "data": [{
                        "produk_id": produk_id
                }]
            },
            "id": "1"
        }
        return this.httpClient.post(this.baseUrl, JSON.stringify(par), { headers });
    }

    public getParentCompany(company_id) {
        let headers = this.generateHeader();
        let par = {
            "jsonrpc": "2.0",
            "method": "getParentCompany",
            "params": {
                "data": [{
                        "company_id": company_id
                }]
            },
            "id": "1"
        }
        return this.httpClient.post(this.baseUrl, JSON.stringify(par), { headers });
    }
    /*public getCompany(produk_id,company_parent_id) {
        let headers = this.generateHeader();
        let par = {
            "jsonrpc": "2.0",
            "method": "getCompany",
            "params": {
                "data": [
                    {
                    "produk_id": produk_id,
                    //"company_parent_id": company_parent_id
                    }
                ]
            },
            "id": "1"
        }
        return this.httpClient.post(this.baseUrl, JSON.stringify(par), { headers });
    }*/
    public getCompany(produk_id,search,kec) {
        let headers = this.generateHeader();
        let cari = '';
        let par = {
            "jsonrpc": "2.0",
            "method": "getCompany",
            "params": {
                "data": [
                    {
                        "produk_id" : produk_id,
                        "search_kec" : kec,
                        "search" : search,
                    }
                ]
            },
            "id": "1"
        }
        
        return this.httpClient.post(this.baseUrl, JSON.stringify(par), { headers });
    }
    public getCompanyProduk(produk_id,company_id) {
        let headers = this.generateHeader();

        let par = {
            "jsonrpc": "2.0",
            "method": "getCompanyProduk",
            "params": {
                "data": [
                    {
                        "produk_id": produk_id,
                        "company_id": company_id
                    }
                ]
            },
            "id": "1"
        }
        return this.httpClient.post(this.baseUrl, JSON.stringify(par), { headers });
    }
    public setInvoice(par) {
        let headers = this.generateHeader();
        return this.httpClient.post(this.baseUrl, JSON.stringify(par), { headers });
    }

    public getLokasi(produk_id,key) {
        let headers = this.generateHeader();

        let par = {
            "jsonrpc": "2.0",
            "method": "getLokasi",
            "params": {
                "data": [{
                    "produk_id" : produk_id,
                    "search":key
                }]
            },
            "id": "1"
         }         
        return this.httpClient.post(this.baseUrl, JSON.stringify(par), { headers });
    }

    public generateHeader(){
        var now = new Date();
        var utc_timestamp = Date.UTC(
            now.getUTCFullYear(),
            now.getUTCMonth(),
            now.getUTCDate(),
            now.getUTCHours(),
            now.getUTCMinutes(),
            now.getUTCSeconds(),
            now.getUTCMilliseconds()
        );
        var str = utc_timestamp.toString();
        this.timestamp = str.substr(0, 10);

        var hash = CryptoJS.HmacSHA256(
            this.username + "&" + this.timestamp,
            this.api_key
        );
        this.signature = hash.toString(CryptoJS.enc.Base64);

        let headers = new HttpHeaders({
            userid: this.username,
            key: this.timestamp,
            signature: this.signature,
        });
        return headers;
    }





    async showToast(message: any) {
        this.istoast = true;
        return await this.toas.create({
            message: message,
            duration: 3000,
            position: "top",
            color: "dark",
        }).then((a) => {
            a.present().then(() => {
                if (!this.istoast) {
                    a.dismiss().then(() => console.log("finish"));
                }
            });
        });
    }
    async hideToast() {
        this.istoast = false;
    }
    async alert(msg) {
        const alert = await this.AlertController.create({
        message: msg,
        buttons: ["OK"],
        });
        await alert.present();
    }

    async showLoading() {
        this.isLoading = true;
        return await this.LoadingController.create({
            duration: 2000,
            message: "",
            backdropDismiss: true,
        }).then((a) => {
            a.present().then(() => {
                console.log("loading");
                if (!this.isLoading) {
                    a.dismiss().then(() => console.log("finish"));
                }
            });
        });
    }

    async hideLoading() {
        this.isLoading = false;
        this.LoadingController.dismiss();
    }
}
