import { AnimationController, Animation } from "@ionic/angular";

export const enterAnimation = (baseEl: HTMLElement, opts?:any) : Animation =>{
  // console.log(baseEl,"baseEl")
  // console.log("opts",opts)

  const animation = new AnimationController();
   if (opts.direction !== "forward"){
const a1 =animation.create()
.addElement(opts.enteringEl)
.duration(150)
.easing('ease-in')
.fromTo('opacity',0.6,0)
.fromTo('transform', 'translateX(10%)', 'translateX(90%)');


const a2 =animation.create()
.addElement(opts.leavingEl)
.duration(150)
.easing('ease-out')
.fromTo('opacity',0.6,0)
.fromTo('transform', 'translateX(10%)', 'translateX(90%)');
return animation.create().addAnimation([a2,a1])
   }

   else {
    const aa= animation.create()
    .addElement(opts.enteringEl)
    .duration(150)
    .easing('ease-in')
    .fromTo('opacity',0,1)
    .fromTo('transform', 'translateX(90%)', 'translateX(10%)');


    const bb= animation.create()
    .addElement(opts.leavingEl)
    .duration(150)
    .easing('ease-out')
    .fromTo('transform', 'translateX(90%)', 'translateX(10%)')
    .fromTo('opacity',0,1);

    return animation.create().addAnimation([aa,bb])
   }
// return null;
}
